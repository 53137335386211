import Joi from 'joi';

const mutualFunds = {
	create: {
		title: Joi.string()
			.min(3)
			.required(),
		description: Joi.string().required(),
		currency: Joi.string().required(),
		minimum_investment_amount: Joi.number()
			.required()
			.label('Minimum investment amount'),
		returns: Joi.number().required(),
		fund_custodian: Joi.string()
			.required()
			.label('Fund custodian'),
		fund_trustees: Joi.string()
			.required()
			.label('Fund trustees'),
		fund_manager: Joi.string()
			.required()
			.label('Fund manager'),
		fund_risk_level: Joi.string()
			.required()
			.label('Fund risk level'),
		fund_size: Joi.number()
			.required()
			.label('Fund size'),
		fund_minimum_holding_period: Joi.number()
			.required()
			.label('Fund minimum holding period'),
		fund_management_fee: Joi.number()
			.required()
			.label('Fund management fee'),
		fund_income_distribution: Joi.string()
			.required()
			.label('Fund income distribution'),
		fund_portfolio: Joi.string()
			.required()
			.label('Fund portfolio'),
		fund_reason: Joi.string()
			.required()
			.label('Fund reason'),
		past_performance: Joi.string()
			.required()
			.label('Past performance')
	}
};

export default mutualFunds;
