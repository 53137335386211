/* eslint-disable max-len */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Name:</p>
							<p id="mutualFundTitle">{{ itemData.title }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Currency:</p>
							<p id="mutualFundCurrency">{{ itemData.currency }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Description:</p>
							<p id="mutualFundDescription">{{ itemData.description }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Minimum Investment Amount:</p>
							<p id="mutualFundMininumInvestmentAmount">{{ itemData.minimum_investment_amount }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Returns:</p>
							<p id="mutualFundReturns">{{ itemData.returns }}</p>
						</div>
						<div v-if="itemData.fund_custodian">
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Custodian:</p>
								<p id="mutualFundReturns">{{ itemData.fund_custodian }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Income Distribution:</p>
								<p id="mutualFundReturns">{{ itemData.fund_income_distribution }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Management Fee:</p>
								<p id="mutualFundReturns">{{ itemData.fund_management_fee }}%</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Manager:</p>
								<p id="mutualFundReturns">{{ itemData.fund_manager }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Manager:</p>
								<p id="mutualFundReturns">{{ itemData.fund_manager }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Mninimum Holding Period:</p>
								<p id="mutualFundReturns">{{ itemData.fund_minimum_holding_period }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Risk Level:</p>
								<p id="mutualFundReturns">{{ itemData.fund_risk_level }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Portfolio:</p>
								<div v-html="itemData.fund_portfolio" id="mutualFundReturns"></div>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Size:</p>
								<p id="mutualFundReturns">
									{{ itemData.fund_size | formatCurrency(itemData.currency) }}
								</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Fund Trustee:</p>
								<p id="mutualFundReturns">{{ itemData.fund_size }}</p>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Past Performance:</p>
								<div v-html="itemData.past_performance" id="mutualFundReturns"></div>
							</div>
							<div class="user-profile__item">
								<p class="user-profile__section__title">Past Reason:</p>
								<p id="mutualFundReturns">{{ itemData.fund_reason }}</p>
							</div>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Status:</p>
							<p>
								<span :class="statusStyle" id="mutualFundStatus">
									{{ itemData.active === 0 ? 'Inactive' : 'Active' }}
								</span>
							</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Edit
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="">
								<form method="post" @submit.prevent="update">
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Name</label>
													<input
														:class="[{ 'is-invalid': errors.title }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.title"
														name="title"
														type="text"
														class="form-control"
														id="name"
													/>
												</div>
												<div class="invalid-feedback" id="mutualFundTitleError">
													{{ errors.title }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="currency">Currency</label>
													<select
														v-model="itemData.currency"
														class="form-control"
														id="currency"
														:class="[{ 'is-invalid': errors.currency }]"
														@keydown="clearErrors($event.target.name)"
													>
														<option value="NGN">NGN</option>
														<option value="USD">USD</option>
													</select>
												</div>
												<div class="invalid-feedback" id="mutualFundCurrencyError">
													{{ errors.currency }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="returns">Returns</label>
													<input
														:class="[{ 'is-invalid': errors.returns }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.returns"
														name="returns"
														type="text"
														class="form-control"
														id="returns"
														placeholder="e.g 10"
													/>
												</div>
												<div class="invalid-feedback" id="mutualFundReturnsError">
													{{ errors.returns }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="minimum_investment_amount">
														Minimum Investment Amount
													</label>
													<input
														:class="[{ 'is-invalid': errors.minimum_investment_amount }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.minimum_investment_amount"
														name="minimum_investment_amount"
														type="text"
														class="form-control"
														id="minimum_investment_amount"
														placeholder="e.g 500"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.minimum_investment_amount }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_custodian">
														Fund Custodian
													</label>
													<input
														:class="[{ 'is-invalid': errors.fund_custodian }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_custodian"
														name="fund_custodian"
														type="text"
														class="form-control"
														id="fund_custodian"
														placeholder="e.g Citibank Nigeria"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_custodian }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_trustees">
														Fund Trustees
													</label>
													<input
														:class="[{ 'is-invalid': errors.fund_trustees }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_trustees"
														name="fund_trustees"
														type="text"
														class="form-control"
														id="fund_trustees"
														placeholder="e.g First trustees"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_trustees }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_manager">
														Fund Manager
													</label>
													<input
														:class="[{ 'is-invalid': errors.fund_manager }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_manager"
														name="fund_manager"
														type="text"
														class="form-control"
														id="fund_manager"
														placeholder="e.g XYZ Enterprise"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_manager }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_risk_level">
														Fund Risk Level
													</label>
													<select
														v-model="itemData.fund_risk_level"
														class="form-control"
														name="fund_risk_level"
														id="fund_risk_level"
														:class="[{ 'is-invalid': errors.fund_risk_level }]"
														@change="clearErrors($event.target.name)"
													>
														<option value="Low">Low</option>
														<option value="Medium">Medium</option>
														<option value="High">High</option>
													</select>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_risk_level }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_size">
														Fund Size
													</label>
													<input
														:class="[{ 'is-invalid': errors.fund_size }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_size"
														name="fund_size"
														type="text"
														class="form-control"
														id="fund_size"
														placeholder="e.g 100, 000, 000"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_size }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_minimum_holding_period">
														Fund Minimum Holding Period
													</label>
													<input
														:class="[{ 'is-invalid': errors.fund_minimum_holding_period }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_minimum_holding_period"
														name="fund_minimum_holding_period"
														type="text"
														class="form-control"
														id="fund_minimum_holding_period"
														placeholder="e.g 30"
													/>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_minimum_holding_period }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_income_distribution">
														Fund Income Distribution
													</label>
													<select
														v-model="itemData.fund_income_distribution"
														class="form-control"
														name="fund_income_distribution"
														id="fund_income_distribution"
														:class="[{ 'is-invalid': errors.fund_income_distribution }]"
														@change="clearErrors($event.target.name)"
													>
														<option value="Daily">Daily</option>
														<option value="Monthly">Monthly</option>
														<option value="Quarterly">Quarterly</option>
														<option value="Annually">Annually</option>
														<option value="Periodically">Periodically</option>
													</select>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_income_distribution }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_portfolio">
														Fund Portfolio
													</label>
													<ckeditor
														:editor="editor"
														v-model="itemData.fund_portfolio"
														:config="editorConfig"
													></ckeditor>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_portfolio }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="fund_reason">
														Fund Reason
													</label>
													<ckeditor
														:editor="editor"
														v-model="itemData.fund_reason"
														:config="editorConfig"
													></ckeditor>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.fund_reason }}
												</div>
											</div>

											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="past_performance">
														Past Performance
													</label>
													<ckeditor
														:editor="editor"
														v-model="itemData.past_performance"
														:config="editorConfig"
													></ckeditor>
												</div>
												<div
													class="invalid-feedback"
													id="mutualFundMininumInvestmentAmountError"
												>
													{{ errors.past_performance }}
												</div>
											</div>

											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="fund_management_fee">Fund Management Fee</label>
													<input
														:class="[{ 'is-invalid': errors.fund_management_fee }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.fund_management_fee"
														name="fund_management_fee"
														type="text"
														class="form-control"
														id="fund_management_fee"
														placeholder="e.g 10"
													/>
												</div>
												<div class="invalid-feedback" id="mutualFundReturnsError">
													{{ errors.fund_management_fee }}
												</div>
											</div>

											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="description">Description</label>
													<input
														:class="[{ 'is-invalid': errors.description }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.description"
														name="description"
														type="text"
														class="form-control"
														id="description"
													/>
												</div>
												<div class="invalid-feedback" id="mutualFundDescriptionError">
													{{ errors.description }}
												</div>
											</div>
										</div>
										<div class="settings__primary">
											<button
												@click.prevent="deactivateMutualFund"
												class="btn"
												:class="mutualFund"
												type="button"
												data-dismiss="modal"
												aria-label="close"
												name="button"
											>
												{{
													itemData.active === 1
														? 'Deactivate Mutual Fund'
														: 'Activate Mutual Fund'
												}}
											</button>
											<button
												class="btn btn__save"
												type="button"
												name="button"
												@click.prevent="update"
												v-bind="{ disabled: loading }"
											>
												Save
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import form from '../../mixins/form';
import Loader from '../../components/Loader';
import mutualFunds from '../../utils/validations/mutualFunds';

export default {
	name: 'MutualFundDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true,
			editor: ClassicEditor,
			editorConfig: {
				toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList'],
				heading: {
					options: [
						{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
						{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
						{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
						{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
					]
				}
			}
		};
	},
	created() {
		const data = {
			id: this.$route.params.id,
			status: this.$route.query.status
		};
		this.fetchMutualFundDetails(data);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getMutualFundDetails;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getMutualFundDetails']),
		statusStyle() {
			return [this.itemData.active === 0 ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-success'];
		},
		mutualFund() {
			return [this.itemData.active === 1 ? 'btn__danger' : 'btn__save--outline'];
		}
	},
	methods: {
		...mapActions(['updateMutualFunds', 'fetchMutualFundDetails']),

		update() {
			const data = this.itemData;
			Object.keys(data).forEach((key) => {
				if (data[key] === null) {
					delete data[key];
				}
			});
			['data', 'withdraw_date', 'is_deleted', 'is_deleted', 'updated_at', 'created_at', 'id'].forEach((item) => {
				delete data[item];
			});

			delete data.active;

			this.validate(data, mutualFunds.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			const payload = { data, id: this.$route.params.id };
			this.updateMutualFunds(payload);
			return true;
		},
		deactivateMutualFund() {
			this.itemData.active = this.itemData.active === 0 ? 1 : 0;
			const data = { active: this.itemData.active };
			const payload = { data, id: this.$route.params.id };
			this.updateMutualFunds(payload);
		}
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
</style>
